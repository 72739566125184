<template>
  <div class="co-flex-col">
    <land-gallery
      :items="tab.items"
      :type="tab.type"
      :ratio="tab.ratio"
      :tooled="tab.tooled"
      :albumed="false"
      margin="ma-2"
      back-color="blue-grey-lighten-5"
      per-columns="1"
      @click="onContent($event, tab)"
      @action="onAction"
    />
    <dialog-alert ref="dlgAlert" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  // import lib from '@/api/co.lib'
  import mixPreset from '@/pages/mixins/mix.preset'

  const parts = mixPreset.Parts
  const names = mixPreset.Names
  export default {
    name: 'SectionIssueCenterArticle',
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    data () {
      return {
        tab: parts[names.ARTICLE],
        handlers: {
          like: this.toLike,
          forward: this.toForward
        }
      }
    },
    created () {
      this.loadContent()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      toLikeCancel (index, item) {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            let num = parseInt(item.likesNum, 10) || 0
            if (num < 1) {
              num = 0
            } else {
              num -= 1
            }
            me.$set(item, 'likesNum', num)
            me.showAlert('success', '取消点赞成功')
          } else {
            const err = res.data || { code: 9000, desc: '未知错误' }
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        web.behaviour.cancel({
          id: item.contentId,
          type: 'like',
          executed
        })
      },
      toLike (ev) {
        const me = this
        const index = ev.index
        const item = ev.item
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            let num = parseInt(item.likesNum, 10) || 0
            if (num < 0) {
              num = 0
            }
            num += 1
            me.$set(item, 'likesNum', num)
            me.showAlert('success', '点赞成功')
          } else {
            const err = res.data || {}
            if (err.code === 1001) {
              me.toLikeCancel(index, item)
            } else {
              const err = res.data || { code: 9000, desc: '未知错误' }
              me.showAlert('error', `${err.code}-${err.desc}`)
            }
          }
        }

        web.behaviour.like({
          contentId: item.contentId,
          executed
        })
      },
      toForward (ev) {
        const me = this
        const item = ev.item
        const text = api.comm.trim(ev.action.content)
        if (text) {
          const contentId = item.contentId
          const executed = function (res) {
            // console.log({ res })
            if (res.status) {
              let value = parseInt(item.forwardedNum, 10) || 0
              value += 1
              me.$set(item, 'forwardedNum', value)
              me.showAlert('success', '转发成功')
            } else {
              const err = res.data || { code: 9000, desc: '未知错误' }
              me.showAlert('error', `${err.code}-${err.desc}`)
            }
          }

          web.behaviour.forward({
            contentId,
            forwardedText: text,
            executed
          })
        } else {
          this.$alert('填好转发后显示标题才能转发！', '提示', {
            confirmButtonText: '好的',
            type: 'warning'
          })
        }
      },
      onAction (ev) {
        console.log({ ev })
        const action = ev.action || {}
        const handler = this.handlers[action.name]
        if (typeof handler === 'function') {
          handler(ev)
        }
      },
      onContent (ev, navigation) {
        const item = ev.item
        this.toContent(item, navigation)
      },
      toContent (item, navigation) {
        const params = {
          name: navigation.name,
          contentId: item.contentId,
          type: item.type,
          title: item.title
        }

        api.page.navigate(this, 'hotDetail', params)
      },
      loadContent () {
        const obj = this.tab
        const executed = function (res) {
          // console.log('me.dataList: %o', me.dataList)
          if (res.status) {
          }
        }

        obj.params = web.content.getParams({
          type: obj.type,
          state: web.comm.States.PUBLISH,
          pageSize: '3',
          caches: obj.items,
          executed
        })

        obj.params.reload = true
        api.httpx.getItems(obj.params)
      }
    }
  }
</script>
